<template>
  <div class="main-container">
    <div>
      <div class="search-box">
        <a-input-search
          v-model="title"
          placeholder="请输入搜索内容"
          enter-button="搜索"
          size="large"
          @search="onSearch"
        />
        <div class="title-tips" v-show="tipsState">*请输入搜索内容</div>
      </div>
      <div class="result">
        <div class="example" v-if="loading">
          <a-spin size="large" />
        </div>
        <div
          class="flex-wrap flex-vertical flex-center not-img"
          v-else-if="this.infoList.length <= 0"
        >
          <img src="@/assets/zwsj.png" alt="" />
          <div>暂无数据</div>
        </div>
        <ul v-else>
          <li
            class="flex-wrap flex-h-center"
            @click="openNewsDetails(item)"
            v-for="(item, index) in infoList"
            :key="index"
          >
            <div class="date">
              <div>{{ getDate(item.createTime).day }}</div>
              <div>{{ getDate(item.createTime).yearMonth }}</div>
            </div>
            <div class="flex-con news">
              <p class="title">{{ item.title }}</p>
              <p class="news-content overhide">
                {{ getText(item.content) }}...
              </p>
            </div>
          </li>
        </ul>
        <div class="paging">
          <a-pagination
            v-model="page.current"
            :pageSize="page.pageSize"
            :total="page.total"
            hideOnSinglePage
            @change="changePage"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { yqmsInformationList } from '@/api/api'

export default {
  data() {
    return {
      page: {
        pageSize: 5,
        current: 1,
        total: 0,
      },
      infoList: [],
      loading: false,
      title: '',
      tipsState: false,
    }
  },
  beforeRouteLeave(to, from, next) {
    if (to.name != 'NewsDetails') {
      this.title = ''
      this.tipsState = false
      this.infoList = []
      this.page = {
        pageSize: 5,
        current: 1,
        total: 0,
      }
    }
    next()
  },
  methods: {
    onSearch() {
      if (this.title) {
        this.tipsState = false
        this.getInfoList()
      } else {
        this.tipsState = true
      }
    },
    async getInfoList(typeId) {
      this.loading = true
      this.typeId = typeId
      try {
        const params = {
          pageNo: this.page.current,
          pageSize: this.page.pageSize,
          title: this.title,
          notStatus: 0,
        }
        const res = await yqmsInformationList(params)
        // console.log(res)
        if (res.code == 200) {
          this.infoList = res.result.records
          this.page.current = res.result.current
          this.page.total = res.result.total
          this.loading = false
        } else {
          this.$message.error(res.message)
          this.loading = false
        }
      } catch (err) {
        this.$message.error(err.message)
        this.loading = false
      }
    },
    changePage(page) {
      this.page.current = page
      this.getInfoList(this.typeId)
    },
    getText(str) {
      return str
        .replace(/<[^<>]+>/g, '')
        .replace(/&nbsp;/gi, '')
        .replace(/&ldquo;/gi, '')
        .replace(/&rdquo;/gi, '')
        .replace(/&rdquo;/gi, '')
        .replace(/&mdash;/gi, '')
        .replace(/&middot;/gi, '')
        .replace(/\s/g, '')
    },
    getDate(str) {
      const date = str.split(' ')[0]
      const year = date.split('-')[0]
      const month = date.split('-')[1]
      const day = date.split('-')[2]
      return {
        day: day,
        yearMonth: year + '/' + month,
      }
    },

    openNewsDetails(data) {
      this.$router.push({
        name: 'NewsDetails',
        params: {
          data: encodeURIComponent(JSON.stringify(data)),
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.main-container {
  background: #f4f4f4;
  > div {
    width: 1200px;
    margin: 0 auto;
    padding: 50px 0;

    .search-box {
      width: 600px;
      margin: 0 auto;

      /deep/ .ant-btn-lg {
        padding: 0 40px;
      }

      .title-tips {
        color: red;
        margin: 5px;
      }
    }

    .result {
      background: #fff;
      margin-top: 50px;
      border-radius: 4px;
      padding: 20px 20px 50px 20px;
      min-height: 600px;
      position: relative;

      ul {
        li {
          padding: 12px 26px;
          cursor: pointer;
          .date {
            width: 99px;
            height: 99px;
            background: rgba(208, 231, 247, 0.7);
            border-radius: 5px;
            color: #1285d6;

            > div {
              text-align: center;
              &:nth-child(1) {
                font-size: 42px;
                font-weight: 500;
              }

              &:nth-child(2) {
                font-size: 18px;
                font-weight: 400;
              }
            }
          }

          .news {
            margin-left: 18px;
            .title {
              color: #333;
              font-weight: bold;
            }

            .news-content {
              font-size: 12px;
              color: #666;
              margin-top: 20px;
            }
          }
        }
      }

      .paging {
        text-align: right;
        padding: 20px;
        position: absolute;
        bottom: 0;
        right: 0;
      }

      .example {
        text-align: center;
        padding: 311px 0;
      }

      .not-img {
        font-size: 18px;
        color: #9cb9fa;
        padding: 197px 0;
        > div {
          margin-top: 20px;
        }
      }
    }
  }
}
</style>
